import './SectionGiveaways.css'
import {Container} from 'react-bootstrap'
import { SectionNFTCollection } from './SectionGeneric';
import { SectionSupportResistanceLevels } from './SectionSupportResistance';

export const SectionGiveaways = (props) => {
    const twitter = 'https://twitter.com/InvasionEth'
    const discordhref = 'https://discord.gg/4guwfvhgbr';

    return (
      <Container id={'giveaways'} fluid className="section-collection">
        <h1>Giveaway Calendar</h1>
        <p>We are giving away <b>+230 NFT Rebels</b> from Generation 1 to Generation 9.</p>
        <p>To participate you have to follow us on <a className="link" title="Twitter" target="_blank" rel="noreferrer" href={twitter}>Twitter</a> or <a className="link" title="Twitter" target="_blank" rel="noreferrer" href={discordhref}>Discord</a> and drop your wallet there.</p>
      </Container>
    );
}

export const SectionGiveawaysContent = (props) => {
  return (
    <>
      <GiveawayEntry title="Generation 1" date="April 2022"
        content={
        <>
          <p>Batch size: <b>500</b> Rebels.</p>
          <p>Listing price: <b>0.006 ETH</b></p>
          <hr></hr>
          <p>We'll give away <b>50</b> Generation 1 Rebels.</p>
          <p>When we reach 100 followers on twitter, the first 10 will be given away.</p>
          <p>Then, for each 10 new followers we'll give away 1 extra Rebel.</p>
        </>
        }>
      </GiveawayEntry>    
      <GiveawayEntry title="Generation 2" date="May 2022"
        content={
        <>
          <p>Batch size: <b>500</b> Rebels.</p>
          <p>Listing price: <b>0.0085 ETH</b> *</p>
          <hr></hr>
          <p>We'll give away <b>44</b> Generation 2 Rebels.</p>
          <p>Conditions for starting the Giveaway TBA.</p>
          <p><small>* or Generation 1 floor price (higher between those).</small></p>
        </>
        }>
      </GiveawayEntry>    
      <GiveawayEntry title="Generation 3" date="June 2022"
        content={
        <>
          <p>Batch size: <b>500</b> Rebels.</p>
          <p>Listing price: <b>0.011 ETH</b> *</p>
          <hr></hr>
          <p>We'll give away <b>38</b> Generation 3 Rebels.</p>
          <p>Conditions for starting the Giveaway TBA.</p>
          <p><small>* or Generation 2 floor price (higher between those).</small></p>
        </>
        }>
      </GiveawayEntry>    
      <GiveawayEntry title="Generation 4 to 9" date="TBA"
        content={
        <>
          <p>Batch size: <b>500</b> Rebels.</p>
          <p>Listing price: +0.0025 ETH from previous Generation list price or floor price (higher between those).</p>
          <hr></hr>
          <p>Conditions for starting the Giveaway TBA.</p>
        </>
        }>
      </GiveawayEntry>    
      <GiveawayEntry title="Chances" date="April 2022"
        content={
        <>
          <p><b>IMPORTANT!</b></p>
          <p>You have to be a follower (Twitter or Discord) when we make the giveaway.</p>
          <p>You can only get 1 free Rebel from each Generation giveaway.</p>
          <p>For the first 100 followers we have a free Resistance Follower NFT.</p>
          <p>Hurry up and claim yours to participate!</p>
          {/*
          <div className="section-collection">
            <div className="nft-collection-col">
              <SectionNFTCollection title="Chances" 
                      elements={[
                        {q:"Resistance Follower", text:"1x" },
                        {q:"Rebel Wannabe", text:"1x" },
                        {q:"Resistance Fan (Angel)", text:"2x" },
                        {q:"Rebel (not giveaway)", text:"3x" },
                        {q:"Rebel Founding Squad", text:"4x" }
                        ]}>
              </SectionNFTCollection>  
            </div>
          </div>
          <SectionSupportResistanceLevels/>
          */}
        </>
        }>
      </GiveawayEntry>    
    </>
  );
}

const GiveawayEntry = (props) => {
  return (
    <Container fluid className="giveaway-entry">
      <h2>{props.title}</h2>
      <h4>Date: {props.date}</h4>
      {props.content}
    </Container>

  );
}


