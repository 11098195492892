import "./SectionCollection.css";
import "./SectionScaredEarthlings.css";
import {Container, Row, Col} from 'react-bootstrap'

export const SectionScaredEarthlings = (props) => {
    return (
      <Container id={'scaredearthlings'} fluid className="section-collection">
          <h1>Scared Earthlings</h1>
          <Container fluid>
            <Row>
              <Col md="12">
                <p>Save Earthlings while the invaders are here!</p>
                <p>Exclusive animated designs.</p>
                <p>20 phases - 20 earthlings each.</p>
                <div className="scared-earthling-img-group">
                  <img className="scared-earthling-img" src={require("../css/images/Scared-Earthling-1.gif")} alt=""/>
                  <img className="scared-earthling-img" src={require("../css/images/Scared-Earthling-2.gif")} alt=""/>
                  <img className="scared-earthling-img" src={require("../css/images/Scared-Earthling-3.gif")} alt=""/>
                  <img className="scared-earthling-img" src={require("../css/images/Scared-Earthling-4.gif")} alt=""/>
                </div>                  
                <hr></hr>
                <a className="button button-reverse opensea" title="Scared Earthlings - Open Sea" target="_blank" rel="noreferrer" href="https://opensea.io/collection/scared-earthlings">
                    <span>Check out the collection</span>
                </a>
              </Col>
            </Row>
          </Container>
      </Container>
    );
}
