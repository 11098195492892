import React from "react";
import { Link } from "react-router-dom";
import './Navbar.css';

function Navbar({children}) {
  const discordhref = 'https://discord.gg/4guwfvhgbr';
  const openseacollection = 'https://opensea.io/collection/invasion-eth-resistance';
  const twitter = 'https://twitter.com/InvasionEth'

  return (
      <div className="navbar">
          <a className="button" title="Connect to Discord" target="_blank" rel="noreferrer"  href={discordhref}>
            <img src={require("./css/images/logo-discord.png")} alt=""/>
          </a>
          <a className="button" title="Open Sea Market" target="_blank" rel="noreferrer" href={openseacollection}>
            <img src={require("./css/images/logo-opensea.png")} alt=""/>
          </a>
          <a className="button" title="Twitter" target="_blank" rel="noreferrer" href={twitter}>
            <img src={require("./css/images/logo-twitter.png")} alt=""/>
          </a>
          <Link to="/roadmap" className="button" title="Roadmap">
            <img src={require("./css/images/icon-roadmap.png")} alt=""/>
          </Link>
      </div>
  );
}

export default Navbar;
