import React from "react";
import Navbar from './Navbar'
import Main from './Main'
import "./App.css";
import { HashLink as Link } from "react-router-hash-link";

class App extends React.Component {
  componentDidMount() {
  }

  render() {
    return (
      <>
        <Navbar/>
        <Main/>
        <Link className="button-home" title="Home" to="/#home">
          <img src={require("./css/images/logo.png")} alt=""/>
        </Link>        
      </>
    );
  }
}

export default App;