import './SectionRoadmap.css'
import {Container} from 'react-bootstrap'

export const SectionRoadmap = (props) => {
    return (
      <Container id={'roadmap'} fluid className="section-collection">
        <h1>Roadmap</h1>
        <p>We are working hard as the D-Day approaches.</p>
        <p>The roadmap below may be modified before the announcement of the rules of engagement.</p>
      </Container>
    );
}

export const SectionRoadmapContent = (props) => {
  return (
    <>
      <RoadmapEntry title="First Wave" date="April 2022"
        content={
        <>
          <p>The invading army deploys.</p>
          <p>1,111 Invaders will be travelling through the cyberspace.</p>
          <p>A first group of rebels will appear. They will be known as the Founding Squad.</p>
        </>
        }>
      </RoadmapEntry>    
      <RoadmapEntry title="The Resistance is here!" date="April-May 2022"
        content={
        <>
          <p>Each month, 500 Rebels, 500 Pets and 500 Gadgets will be minted and listed.</p>
          <p>10 random Rebels will be reserved from each batch.</p>
          <p>The first batch price will be 0.006 ETH.</p>
          <p>The price of each Rebel batch will increase by a factor of 0.0015 ETH from week to week.</p>
          <p>Gen 1 Listing Date: May 14 2022</p>
          <p>In the first 9 batchs (Gen 1-9) we'll giveaway some Rebels to our followers.</p>
          <p>Alien Dummies will be revealed for practice purposes.</p>
        </>
        }>
      </RoadmapEntry>    
      <RoadmapEntry title="Rules of engagement" date="May 2022"
        content={
        <>
          <p>Rules of engagement, use of capture points and scoring system will be announced.</p>
          <p>Beta version of the Resistance Terminal web app will be deployed and ready to accept new members.</p>
          <p>Be aware of scouting parties!</p>
        </>
        }>
      </RoadmapEntry>    
      <RoadmapEntry title="D-Day" date="July 2022 + Gen 1-4 90% sold"
        content={
        <>
          <p>The first wave will be revealed.</p>
          <p>The hunt only ends after the last invader is catched.</p>
        </>
        }>
      </RoadmapEntry>    
      <RoadmapEntry title="Waves 2 - 5" date="TBA"
        content={
        <>
          <p>Following the same pattern as the first wave, waves 2 through 5 will be revealed every 8 weeks.</p>
        </>
        }>
      </RoadmapEntry>    
      <RoadmapEntry title="The arrival of the monarchs" date="TBA"
        content={
        <>
          <p>The Queen and the King will be revealed.</p>
        </>
        }>
      </RoadmapEntry>    
      <RoadmapEntry title="Scouts" date="At any moment"
        content={
        <>
          <p>Remember that the invaders will send out Scouting parties at any time during the invasion.</p>
          <p>Stay alert and have a happy hunting!</p>
        </>
        }>
      </RoadmapEntry>    
      <RoadmapEntry title="Final Thoughts" date="Now"
        content={
        <>
          <p>This is an art collection with a twist, it's not a game, so you won't find any complex interfaces or 3D engines.</p>
          <p>In the dashaboard you'll find basic information about your profile, owned NFTs, CP balance and your score.</p>
          <p>The tasks you need to complete to get CP are very simple (ex: join our Discord server). A full list will be posted.</p>
          <p>Thanks for reading.</p>
          <p>Have fun!</p>
          <p style={{textAlign: "right"}}><b>The Invasion Eth Team</b></p>
        </>
        }>
      </RoadmapEntry>    
    </>
  );
}

const RoadmapEntry = (props) => {
  return (
    <Container fluid className="roadmap-entry">
      <h2>{props.title}</h2>
      <h4>Date: {props.date}</h4>
      {props.content}
    </Container>

  );
}


