import React from "react";
import Section from '../section/Section'
import "./Resistance.css";
import {SectionFooter} from "../section/SectionGeneric";
import { Container, Row, Col } from "react-bootstrap";

class Resistance extends React.Component {
  componentDidMount() {
  }

  render() {
  return (
      <main className="parallax">
        <Section className="section section-parallax section-small"/>
        <Section className="section section-static">
          <Container fluid className="section-collection">
            <h1>Vive La Résistance</h1>
            <Row>
              <Col md={8}>
              <p>The Founding Squad is assembling.</p>
              <p>They're calling all rebels around the world to join the cause.</p>
              <p>Follow us on <a className="link" title="Discord Server" target="_blank" rel="noreferrer" href="https://discord.gg/4guwfvhgbr">Discord</a>, claim your follower badge and stay tuned!</p>
              <div className="footer">
                <a className="button button-reverse opensea" title="The Resistance - Open Sea" target="_blank" rel="noreferrer" href="https://opensea.io/collection/invasion-eth-resistance">
                  Open Sea Collection
                </a>
              </div>
            </Col>
              <Col md={4}>
                <img className="invaders-wave-1" src={require("../css/images/resistance-founder.png")} alt=""/>
              </Col>
            </Row>            
          </Container>
        </Section>        
        <Section className="section section-parallax section-small"/>
        <Section className="section section-parallax rebels"/>
        <Section className="section section-parallax section-small"/>
        <Section className="section section-non section-footer"  content={<SectionFooter/>}/>
      </main>
    );
  }
}

export default Resistance;