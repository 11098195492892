import React from "react";
import Section from '../section/Section'
import "./Invaders.css";
import {SectionFooter} from "../section/SectionGeneric";
import { Container, Row, Col } from "react-bootstrap";

class Invaders extends React.Component {
  componentDidMount() {
  }

  render() {

    return (
      <main className="parallax">
        <Section className="section section-parallax section-small"/>
        <Section className="section section-static">
          <Container fluid className="section-collection">
            <h1>The Invasion Has Already Begun!</h1>
            <Row>
              <Col md={4}>
                <img className="invaders-wave-1" src={require("../css/images/invaders-wave-1.png")} alt=""/>
              </Col>
              <Col md={8}>
                <p>The General <i>Wannalotofeth</i> is preparing to deploy the first wave.</p>
                <p>He's coming with 1,000 soldiers, 100 captains and 10 colonels to take all the eth they can find.</p>
                <p>An informant from space has passed this information on to some people on Earth.</p>
                <p>They are grouping and setting up The Resistance.</p>
                <p>Pictures of soldiers of the first wave are being revealed.</p>
              </Col>
            </Row>
          </Container>
        </Section>
        <Section className="section section-parallax section-small"/>
        <Section className="section section-static">
          <Container fluid className="section-collection">
            <h1>First Wave</h1>
            <h2>ETA: July 2022</h2>
            <Row>
              <Col>
              <p>Transmiting images from outer space...</p>
                <div className="invaders-wave-1-group">
                  <img className="invaders-wave-1" src="https://invasioneth.art/invaders/00007536.png" alt=""/>
                  <img className="invaders-wave-1" src="https://invasioneth.art/invaders/00007537.png" alt=""/>
                  <img className="invaders-wave-1" src="https://invasioneth.art/invaders/00007538.png" alt=""/>
                  <img className="invaders-wave-1" src="https://invasioneth.art/invaders/00007539.png" alt=""/>
                </div>                  
                <p>Join us at <a className="link" title="Join The Resistance" target="_blank" rel="noreferrer" href="https://app.invasioneth.art">app.invasioneth.art</a> and stay tuned!</p>
              </Col>
            </Row>
          </Container>
        </Section>
        <Section className="section section-parallax section-small"/>
        <Section className="section section-non section-footer"  content={<SectionFooter/>}/>
      </main>
    );
  }
}

export default Invaders;