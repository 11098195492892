import "./SectionCollection.css";
import {Container, Row, Col} from 'react-bootstrap'
import {SectionQA, SectionNFTCollection} from './SectionGeneric'
import { Link } from "react-router-dom";

export const SectionInvaders = (props) => {
    return (
      <Container id={'invaders'} fluid className="section-collection">
          <h1>The Invaders</h1>
          <Container fluid>
            <Row>
              <Col md="12">
                <p>The invasion from outer space has already begun.</p>
                <p>The first wave is coming!</p>
                <p>They are coming for all the <b>ETH</b> they can harvest (they have special equipment for the job).</p>
                <p><b>Invasion Eth - Invaders</b> is a Polygon NFT collection consisting of <b>5,857</b> items.</p>
              </Col>
            </Row>
            <Row>
              <Col md="12" className="nft-collection-col">
              <SectionNFTCollection title="Complete Collection" 
                  elements={[
                    {q:"5,000", text:"Soldiers" },
                    {q:"500", text:"Captains" },
                    {q:"300", text:"Scouts" },
                    {q:"50", text:"Colonels" },
                    {q:"5", text:"Generals" },
                    {q:"1", text:"Queen" },
                    {q:"1", text:"King" }
                    ]}>
                </SectionNFTCollection>                
              </Col>
            </Row>
            <hr></hr>
            <Row>
              <Col md="12" className="nft-collection-col">
                <p>The generals plan an attack in five (5) waves.</p>
                <p>With each wave, 1,111 items will arrive, minted and listed according to the <Link className="link" to="/roadmap">Roadmap</Link>.</p>
                <SectionNFTCollection title="Composition of each wave" 
                  elements={[
                    {q:"1,000", text:"Soldiers" },
                    {q:"100", text:"Captains" },
                    {q:"10", text:"Colonels" },
                    {q:"1", text:"General" }
                    ]}>
                </SectionNFTCollection>                
              </Col>
            </Row>
            <Row>
              <Col md="12" className="nft-collection-col">
                <p>Scouting parties will arrive without further notice.</p>
                <p>In the final phase the Queen and the King will make their appearance.</p>
                <Link to="/invaders" className="button button-reverse opensea">
                    <span>Check out the collection</span>
                </Link>
              </Col>
            </Row>
            <hr></hr>
            <Row>
              <Col md="12">
                <p>Invaders are spawned by combining different crafted traits and there are unique designs in each wave.</p>
                <p>The five (5) generals, the queen and the king are unique characters and only one of each will be minted.</p>
              </Col>
            </Row>
          </Container>
      </Container>
    );
}

export const SectionInvadersQA = (props) => {
  const qa = [
    { type: "question", text: "Why Polygon?" },
    { type: "answer", text: "Because Polygon is a PoS blockchain working over Ethereum and it's green compared to other PoW blockchains. (<a target='_blank' rel='noreferrer' href='https://blog.polygon.technology/polygon-the-eco-friendly-blockchain-scaling-ethereum-bbdd52201ad/'>Click here for a detailed explanation</a>)" },
    { type: "answer", text: "You can buy and sell items with a small gas fee or no fee at all." },
    { type: "question", text: "Awesome! I really like this collection!" },
    { type: "question", text: "How do I go about buying them?" },
    { type: "answer", text: "Well... You can't. These guys are not for sale." },
    { type: "question", text: "WTF?" },
    { type: "answer", text: "You'll have to catch them!" },
    { type: "question", text: "Fair enough... So... How can I catch them?" },
    { type: "answer", text: "You'll have to join the resistance, my friend." },
    { type: "question", text: "You're driving me nuts!" },
    { type: "question", text: "What's the resistance?!" },
    { type: "answer", text: "Keep scrolling..." }
  ];

  return (
      <SectionQA elements={qa} title="Invaders Q&amp;A"></SectionQA>
  );
}
