import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Home from './pages/Home';
import Invaders from './pages/Invaders';
import Resistance from './pages/Resistance';
import Assets from './pages/Assets';
import Roadmap from './pages/Roadmap';
import GiveAways from './pages/Giveaways'

const Main = () => {
  return (
    <>
    <Routes>
      <Route exact path='/' element={<Home/>}></Route>
      <Route exact path='/giveaways' element={<GiveAways/>}></Route>
      <Route exact path='/roadmap' element={<Roadmap/>}></Route>
      <Route exact path='/invaders' element={<Invaders/>}></Route>
      <Route exact path='/resistance' element={<Resistance/>}></Route>
      <Route exact path='/assets' element={<Assets/>}></Route>
    </Routes>
    </>
  );
}

export default Main;