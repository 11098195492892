import React from "react";
import Section from '../section/Section'
import {SectionGiveaways, SectionGiveawaysContent} from "../section/SectionGiveaways";
import {SectionFooter} from "../section/SectionGeneric";

class Giveaways extends React.Component {
  componentDidMount() {
  }

  render() {
  return (
    <main className="parallax">
                <div id={'home'}></div>
                <Section className="section section-parallax section-small"/>
                <Section className="section section-static" content={<SectionGiveaways/>}/>
                <Section className="section section-static section-transparent section-giveaway-entries" content={<SectionGiveawaysContent/>}/>
                <Section className="section section-parallax section-small"/>
                <Section className="section section-non section-footer"  content={<SectionFooter/>}/>
      </main>
    );
  }
}

export default Giveaways;