import {Container, Row, Col} from 'react-bootstrap'
import ReactHtmlParser from 'html-react-parser';

export const SectionQA = (props) => {
  return (
      <Container fluid className="section-collection section-collection-qa">
        <h1>{props.title}</h1>
        {!props.elements ? '' : props.elements.map((element, idx) => <div className={element.type} key={idx}>{ReactHtmlParser(element.text)}</div>)}
      </Container>
  );
}

export const SectionNFTCollection = (props) => {
  return (
    <>
      <div className="nft-collection">
        <div className="header">{props.title}</div>
      <Container fluid>
        {!props.elements ? '' : props.elements.map((element, idx) => <Row key={idx}><Col className="nft-q">{ReactHtmlParser(element.q)}</Col><Col>{ReactHtmlParser(element.text)}</Col></Row>)}
      </Container>
      </div>
    </>
  );
}

export const SectionFooter = (props) => {
  return (
    <>
      <div><b>Copyright 2022 - art-E-Kan</b></div>
    </>
  );
}
