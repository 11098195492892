import "./Section.css";

const Section = (props) => {
    return (
      <section className={props.className}>
          <h1>{props.title}</h1>
          {props.content}
          {props.children}
      </section>
    );
}

export default Section;
