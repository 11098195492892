import './SectionSupportResistance.css'
import {Container, Row, Col} from 'react-bootstrap'

export const SectionSupportResistance = (props) => {
    return (
      <Container id={'support-resistance'} fluid className="section-collection">
        <h1>Support Us!</h1>
        <p>You can join our <a className="link" title="Discord Server" target="_blank" rel="noreferrer" href="https://discord.gg/4guwfvhgbr">Discord Server</a> to keep up with the latest news.</p>
        <p>By supporting us in this early stage of the project, you will participate in giveaways and airdrops when the invasion begins.</p>
        <p>Check out the support levels!</p>
        <p>Thank you!!!</p>
      </Container>
    );
}

export const SectionSupportResistanceLevels = (props) => {
  return (
    <Container id={'support-resistance-levels'} fluid className="support-levels">
      <Row>
        <Col md={5}>
          <h2>Follower Badge</h2>
          <h4>Discord & Twitter</h4>
          <hr></hr>
          <img src={require("../css/images/resistance-follower.png")} alt=""></img>
          <hr></hr>
          <ul>
            <li>+10 extra capture points when the invasion begins</li>
            <li>Only 300 Resistance Followers available</li>
          </ul>
          <div className="footer">
            <a className="button" title="Resistance Fan" target="_blank" rel="noreferrer" href="https://twitter.com/InvasionEth">
              Follow Us!
            </a>
          </div>
        </Col>    
        <Col className="empty-col" md={2}></Col>
        <Col md={5}>
          <h2>Rebel Wannabe</h2>
          <h4>Rebel</h4>
          <hr></hr>
          <img src={require("../css/images/rebel-wannabe.png")} alt=""></img>
          <hr></hr>
          <ul>
            <li>+15 extra capture points when the invasion begins</li>
            <li>Only 200 Rebel Wannabe available</li>
          </ul>
          {/*
          <div className="footer">
            <a className="button" title="Rebel Wannabe" target="_blank" rel="noreferrer" href="https://opensea.io/assets/matic/0xb54d6cf8220e1b6cc071f0414749a01a3cd1bf3f/16632171241560882840900462580621435406543332698332825844039081535265340129480">
              Join the Resistance!
            </a>
          </div>
          */}
        </Col>
      </Row>
      <Row>
        <Col md={5}>
          <h2>Resistance Fan</h2>
          <h4>Angel</h4>
          <hr></hr>
          <img src={require("../css/images/resistance-fan.png")} alt=""></img>
          <hr></hr>
          <ul>
            <li>+20 extra capture points when the invasion begins</li>
            <li>1.000 Resistance Fans available</li>
          </ul>
          {/*
          <div className="footer">
            <a className="button" title="Resistance Fan" target="_blank" rel="noreferrer" href="https://opensea.io/assets/matic/0x2ec6f17f2f9c49bbc9c059bfcd9e25cb595d8b6f/16632171241560882840900462580621435406543332698332825844039081534165828502504">
              Be an Angel!
            </a>
          </div>
          */}
        </Col>
        <Col className="empty-col" md={2}></Col>
        <Col md={5}>
          <h2>Rebel</h2>
          <h4>Gen x Rebel</h4>
          <hr></hr>
          <img src={require("../css/images/resistance-rebel.png")} alt=""></img>
          <hr></hr>
          <ul>
            <li>+30 extra capture points when the invasion begins</li>
            <li>You'll receive a Pet or Gadget (free mint)</li>
            <li>20 Gens - 500 Rebels each</li>
          </ul>
          <div className="footer">
            <a className="button" title="Founding Squad" target="_blank" rel="noreferrer" href="https://opensea.io/collection/invasion-eth-resistance">
              Get a Rebel!
            </a>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={5}>
          <h2>Founding Squad</h2>
          <h4>Leader</h4>
          <hr></hr>
          <img src={require("../css/images/resistance-founder.png")} alt=""></img>
          <hr></hr>
          <ul>
            <li>+40 extra capture points when the invasion begins</li>
            <li>You'll receive 2x Pets or Gadgets (free mints)</li>
            <li>Each of these rebels has an exclusive Founder's Hat</li>
            <li>50 unique Founding Squad members available</li>
          </ul>
          <div className="footer">
            <a className="button" title="Founding Squad" target="_blank" rel="noreferrer" href="https://opensea.io/collection/invasion-eth-resistance">
              Get a Hat!
            </a>
          </div>
        </Col>
        <Col className="empty-col" md={2}></Col>
        <Col md={5}>
          <h2>Scared Earthlings</h2>
          <h4>Leader</h4>
          <hr></hr>
          <img src={require("../css/images/Scared-Earthling-1.gif")} alt=""></img>
          <hr></hr>
          <ul>
            <li>+40 extra capture points when the invasion begins</li>
            <li>Unique animated desings</li>
          </ul>
          <div className="footer">
            <a className="button" title="Scared Earthling" target="_blank" rel="noreferrer" href="https://opensea.io/collection/scared-earthlings">
              Save an Earthling!
            </a>
          </div>
        </Col>
     </Row>
    </Container>
  );
}