import "./SectionCollection.css";
import {Container, Row, Col} from 'react-bootstrap'
import {SectionNFTCollection, SectionQA} from './SectionGeneric'
import { Link } from "react-router-dom";

export const SectionResistance = (props) => {
    return (
      <Container id={'resistance'} fluid className="section-collection">
          <h2>"Every action has an equal and opposite reaction"</h2>
          <p>Isaac Newton</p>
          <h1>Vive La Résistance!</h1>
          <Container fluid>
            <Row>
              <Col md="12">
                <p>These fellows are part of the resistance (La Résistance).</p>
                <p>They are here to save the day.</p>
                <p><b>La Résistance</b> is a Polygon NFT collection consisting of <b>10,000</b> unique items.</p>
              </Col>
            </Row>
            <Row>
              <Col md="12" className="nft-collection-col">
                <SectionNFTCollection title="Complete Collection" elements={[{q:"10,000", text: "Unique Rebels"}]}></SectionNFTCollection>
                <a className="button button-reverse opensea" title="The Resistance - Open Sea" target="_blank" rel="noreferrer" href="https://opensea.io/collection/invasion-eth-resistance">
                    <span>Check out the collection</span>
                </a>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <p></p>
                <p>Rebels are randomly generated and contain many different traits that make them unique characters.</p>
                <p>Each Rebel is special, but some are more special than others (?)</p>
                <p>The rarity of each NFT is determined by its traits.</p>
                <p>You can check the properties and discover the special traits.</p>
              </Col>
            </Row>
            <Row>
              <Col md="12" className="nft-collection-col">
                <SectionNFTCollection title="Distribution of Rarity" 
                  elements={[
                    {q:"50%", text:"Common" },
                    {q:"30%", text:"Rare" },
                    {q:"15%", text:"Legendary" },
                    {q:"5%", text:"Epic" }
                    ]}>
                </SectionNFTCollection>
              </Col>
            </Row>
            <hr></hr>
            <Row>
              <Col md="12">
                <p>When hunting invaders, members of the resistance have special slots for the use of pets and gadgets.</p>
                <p><b>Assets</b> is a Polygon NFT collection with over <b>20,000</b> items.</p>
                <p>This collection is complementary and you must own a Rebel to use these NFTs.</p>
              </Col>
            </Row>
            <Row>
              <Col md="12" className="nft-collection-col">
              <SectionNFTCollection title="Complete Collection" 
                  elements={[
                    {q:"10,000", text:"Pets" },
                    {q:"10,000", text:"Gadgets" },
                    {q:"1,000", text:"Practice Dummies" }
                    ]}>
                </SectionNFTCollection>
                <a className="button button-reverse opensea" title="The Resistance Assets - Open Sea" target="_blank" rel="noreferrer" href="https://opensea.io/collection/invasion-eth-assets">
                    <span>Check out the collection</span>
                </a>
              </Col>
            </Row>
          </Container>
      </Container>
    );
}


export const SectionResistanceQA = (props) => {
  const qa = [
    { type: "question", text: "Ok, let me see if I got it." },
    { type: "question", text: "There are 3 different collections that make up this interactive art gallery." },
    { type: "answer", text: "Yeap." },
    { type: "question", text: "The Rebels, Pets and Gadgets are for sale." },
    { type: "answer", text: "That's right." },
    { type: "question", text: "To catch Invaders I need to buy a Rebel, a Pet and a Gadget." },
    { type: "answer", text: "Not quite right." },
    { type: "answer", text: "You can hunt Invaders without buying a Rebel." },
    { type: "answer", text: "We want everyone to have a chance to own one." },
    { type: "answer", text: "In each wave, there will be some soldiers reserved for this purpose." },
    { type: "question", text: "Cool." },
    { type: "answer", text: "But... (there's always a but)." },
    { type: "answer", text: "The chances of capturing Invaders increase if you own Rebels, Pets and Gadgets." },
    { type: "answer", text: "When you join the resistance and link your wallet, you get <b>Capture Points (CP)</b>." },
    { type: "answer", text: "The more <b>CP</b> you have, the greater your chances." },
    { type: "answer", text: "These points are not for sale and cannot be transferred, you have to earn <b>CP</b> by completing different missions." },
    { type: "question", text: "Ok, sounds like fun." },
    { type: "answer", text: "When you buy a Rebel, you get access to unlockable content and a code for a random Pet or Gadget." },
    { type: "answer", text: "You can redeem this code in your dashboard after you join the resistance." },
    /*
    { type: "question", text: "How do I join?" },
    { type: "answer", text: "First you need to install <a href='https://metamask.io/faqs/' target='_blank'>Metamask</a>." },
    { type: "answer", text: "Then click on the <b>Join La Résistance!</b> button on the top of the screen." },
    { type: "answer", text: "This will connect the site with your wallet and that's it, you're in." },
    */
    { type: "answer", text: "More info coming soon. Stay tuned!" }
  ];

  return (
    <SectionQA elements={qa} title="Resistance Q&amp;A"></SectionQA>
  );
}
