import React from "react";
import Section from '../section/Section'
import "./Assets.css";
import {SectionFooter} from "../section/SectionGeneric";
import { Container } from "react-bootstrap";

class Assets extends React.Component {
  componentDidMount() {
  }

  render() {
  return (
      <main className="parallax">
        <Section className="section section-parallax section-small"/>
        <Section className="section section-static">
          <Container fluid className="section-collection">
            <h1>Pets And Gadgets</h1>
            <p>The resistance factory is working full-time to produce assets.</p>
            <div className="invaders-wave-1-group">
              <img className="invaders-wave-1" src="https://invasioneth.art/assets/00003a9a.png" alt=""/>
              <img className="invaders-wave-1" src="https://invasioneth.art/assets/00003a99.png" alt=""/>
              <img className="invaders-wave-1" src="https://invasioneth.art/assets/00003aaa.png" alt=""/>
              <img className="invaders-wave-1" src="https://invasioneth.art/assets/00003aac.png" alt=""/>
            </div>                  
            <p>Follow us on <a className="link" title="Discord Server" target="_blank" rel="noreferrer" href="https://discord.gg/4guwfvhgbr">Discord</a> and stay tuned!</p>
          </Container>
        </Section>
        <Section className="section section-parallax invaders"/>
        <Section className="section section-parallax"/>
        <Section className="section section-non section-footer"  content={<SectionFooter/>}/>
      </main>
    );
  }
}

export default Assets;