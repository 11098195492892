import React from "react";
import Section from '../section/Section'
import "./Home.css";
import {SectionInvaders, SectionInvadersQA} from "../section/SectionInvaders";
import {SectionResistance, SectionResistanceQA} from "../section/SectionResistance";
import {SectionSupportResistance, SectionSupportResistanceLevels} from "../section/SectionSupportResistance";
import {SectionFooter} from "../section/SectionGeneric";
import { Link } from "react-router-dom";
import { SectionScaredEarthlings } from "../section/SectionScaredEarthlings";

class Home extends React.Component {
  componentDidMount() {
  }

  render() {
    const maincontent = <>
                            <div id="interactive-collection">
                              <div>Interactive Art Collection</div>
                              <div><small><a href="https://app.invasioneth.art" className="button button-main-link" title="Join Us!" target="_blank" rel="noopener noreferrer">Join The Resistance!</a></small></div>
                              <div><small><Link to="/giveaways" className="button button-main-link" title="Giveaways">Giveaway Calendar</Link></small></div>
                              <div><small>scroll down</small></div>
                            </div>
                          </>

  return (
    <main className="parallax">
                <div id={'home'}></div>
                <Section className="section section-parallax main" content={maincontent}/>
                <Section className="section section-static" content={<SectionInvaders/>} />
                <Section className="section section-parallax invaders"/>
                <Section className="section section-static section-static-qa" content={<SectionInvadersQA/>}/>
                <Section className="section section-parallax resistance"/>
                <Section className="section section-static" content={<SectionResistance/>}/>
                <Section className="section section-parallax invaders"/>
                <Section className="section section-static section-static-qa" content={<SectionResistanceQA/>}/>
                <Section className="section section-parallax rebels"/>
                <Section className="section section-static" content={<SectionScaredEarthlings/>}/>
                <Section className="section section-parallax invaders"/>
                <Section className="section section-static" content={<SectionSupportResistance/>}/>
                <Section className="section section-static section-transparent section-support-levels" content={<SectionSupportResistanceLevels/>}/>
                <Section className="section section-parallax section-small"/>
                <Section className="section section-non section-footer"  content={<SectionFooter/>}/>
      </main>
    );
  }
}

export default Home;